import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import React from 'react'
import { MaterialIcons } from '@expo/vector-icons'

const TodoItem = ({ item, removeItem }) => {
  return (
    <TouchableOpacity onPress={() => removeItem(item.key)}>
      <View style={styles.item}>
        <MaterialIcons name="delete" size={22} color="#555" />
        <Text style={styles.itemText}>{item.text}</Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  item: {
    borderRadius: 10,
    elevation: 3,
    backgroundColor: '#fff',
    shadowOffset: { width: 1, height: 1 },
    shadowColor: '#999',
    shadowOpacity: 0.3,
    shadowRadius: 2,
    paddingHorizontal: 14,
    paddingVertical: 18,
    marginVertical: 5,
    flexDirection: 'row',
    alignItems: 'center'
  },
  itemText: {
    fontFamily: 'roboto-regular',
    marginLeft: 10,
    fontSize: 17,
    color: '#555'
  }
})

export default TodoItem
