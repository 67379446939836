import { View, TextInput, StyleSheet, SafeAreaView } from 'react-native'
import React, { useState, useRef } from 'react'

// components
import FlatButton from './Button'

const AddTodo = ({ addItem }) => {
  const [todo, setTodo] = useState('')
  const todoInput = useRef()

  const submitHandler = () => {
    addItem(todo)
    setTodo('')
    todoInput.current.focus()
  }

  return (
    <View style={{ marginBottom: 20 }}>
      <TextInput
        placeholder={'New todo...'}
        placeholderTextColor={'rgba(0, 0, 0, 0.5)'}
        value={todo}
        onChangeText={(text) => setTodo(text)}
        style={styles.input}
        ref={todoInput}
      />
      <FlatButton text="Add Todo" onPress={submitHandler} />
    </View>
  )
}

const styles = StyleSheet.create({
  input: {
    paddingVertical: 8,
    paddingHorizontal: 8,
    marginVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    fontFamily: 'roboto-regular',
    fontSize: 18,
    lineHeight: 23,
    outlineStyle: 'none'
  }
})

export default AddTodo
