import { StatusBar } from 'expo-status-bar'
import AppLoading from 'expo-app-loading'
import { useFonts } from 'expo-font'
import {
  StyleSheet,
  View,
  Text,
  FlatList,
  Alert,
  TouchableWithoutFeedback,
  Keyboard,
  Platform
} from 'react-native'
import React, { useState } from 'react'

// components
import Header from './components/Header'
import TodoItem from './components/TodoItem'
import AddTodo from './components/AddTodo'

const App = () => {
  let [fontsLoaded] = useFonts({
    'roboto-light': require('./assets/fonts/Roboto-Light.ttf'),
    'roboto-regular': require('./assets/fonts/Roboto-Regular.ttf'),
    'roboto-medium': require('./assets/fonts/Roboto-Medium.ttf'),
    'roboto-bold': require('./assets/fonts/Roboto-Bold.ttf')
  })

  const [todos, setTodos] = useState([
    { text: 'Buy coffee', key: '1' },
    { text: 'Create an app', key: '2' },
    { text: 'Play soccer', key: '3' }
    // { text: 'Recite quran', key: '7' },
    // { text: 'Make prayer', key: '6' },
    // { text: 'Run', key: '5' },
    // { text: 'Buy hamburger', key: '4' }
  ])

  const removeItem = (key) => {
    setTodos((prevTodos) => prevTodos.filter((item) => item.key != key))
  }

  const addItem = (text) => {
    if (text.trim().length > 3) {
      setTodos((prevTodos) => [
        { text, key: Math.random().toString() },
        ...prevTodos
      ])

      // dismiss
      Keyboard.dismiss()
    } else {
      if (Platform.OS === 'web') {
        alert('OOPS! Todos must be over 3 chars long.')
      } else {
        Alert.alert('OOPS!', 'Todos must be over 3 chars long.', [
          { text: 'Understood', onPress: () => console.log('alert closed') }
        ])
      }
    }
  }

  if (!fontsLoaded) return <AppLoading />

  return (
    <View style={styles.container}>
      <Header />
      <View style={styles.content}>
        <AddTodo addItem={addItem} />
        <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
          <View style={styles.list}>
            <FlatList
              data={todos}
              renderItem={({ item }) => (
                <TodoItem item={item} removeItem={removeItem} />
              )}
            />
          </View>
        </TouchableWithoutFeedback>
      </View>
      <StatusBar style="light" />
      <Text style={styles.disclaimer}>Youcef Developer &copy; 2022</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#e9ecef',
    marginBottom: 0
  },
  content: {
    flex: 1,
    padding: 16
  },
  list: {
    marginBottom: 0
  },
  disclaimer: {
    fontFamily: 'roboto-light',
    color: '#666',
    textAlign: 'center',
    marginVertical: 25
  }
})

export default App
