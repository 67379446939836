import { View, Text, StyleSheet, Platform } from 'react-native'
import React from 'react'

const Header = () => {
  return (
    <View style={styles.header}>
      <Text style={styles.title}>My Todos</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  header: {
    height: Platform.OS === 'ios' ? 90 : Platform.OS === 'android' ? 80 : 60,
    paddingTop:
      Platform.OS === 'ios' ? 50 : Platform.OS === 'android' ? 40 : 20,
    backgroundColor: '#6927FF'
  },
  title: {
    fontFamily: 'roboto-medium',
    textAlign: 'center',
    color: '#FFFFFF',
    fontSize: 20,
    fontWeight: 'bold',
    textTransform: 'uppercase'
  }
})

export default Header
