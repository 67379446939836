import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import React from 'react'

const FlatButton = ({ text, onPress }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.button}>
        <Text style={styles.buttonText}>{text}</Text>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {
    borderRadius: 5,
    paddingHorizontal: 16,
    paddingVertical: 10,
    backgroundColor: '#6927FF'
  },
  buttonText: {
    fontFamily: 'roboto-regular',
    color: '#fff',
    textTransform: 'uppercase',
    fontSize: 18,
    textAlign: 'center'
  }
})

export default FlatButton
